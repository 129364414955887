// Generic imports
import React from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"

import { Sidebar, SidebarLeft, SidebarRight } from "../Reusable/Sidebar"
import MustLoggedInWrapper from "../Reusable/MustLoggedInWrapper"
import DashboardNotif from "./dashboard-notif"
import HomeSvg from "../../images/dashboard/home.svg"
import KelasSvg from "../../images/dashboard/kelas.svg"
import PaymentSvg from "../../images/dashboard/payment.svg"
import ProfilSvg from "../../images/dashboard/profil.svg"
import { Styles } from "./style"
// import DashboardSelect from "../Reusable/Input/dashboard-select"
import * as sessionSelectors from "rdx/slices/session/selectors"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const navList = [
  {
    name: "Beranda",
    to: "",
    icon: <img src={HomeSvg} />,
  },
  {
    name: "Kelas",
    to: "kelas",
    icon: <img src={KelasSvg} />,
  },
  {
    name: "Pengaturan Akun",
    to: "pengaturan",
    icon: <img src={ProfilSvg} />,
  },
  {
    name: "Payment",
    to: "transaksi",
    icon: <img src={PaymentSvg} />,
  },
]

const Dashboard = ({ children, chosen, noNotif, user }) => {
  // const [selectMode, setSelectMode] = useState("tutee")

  return (
    <MustLoggedInWrapper>
      <Styles>
        <Sidebar>
          <SidebarLeft className="sidebar-left">
            {/* <DashboardSelect onChange={e => setSelectMode(e.target.value)}>
              <option value="tutee" selected={selectMode === "tutee"}>
                Tutee Mode
              </option>
              <option value="tutor" selected={selectMode === "tutor"}>
                Tutor Mode
              </option>
            </DashboardSelect> */}

            <div className="profile-container">
              <div
                className="prof-pic mb-4"
                style={{
                  backgroundImage: `url(${user.photo})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              />
              <div className="prof-name mb-2">{`${user.first_name} ${user.last_name}`}</div>
              {/* <div className="prof-role">{selectMode}</div> */}
            </div>

            {navList.map((val, index) => (
              <Link
                key={index}
                className={`left-nav ${val.name === chosen ? "active" : ""}`}
                to={`/dashboard/${val.to}`}
              >
                <div className="mr-3">{val.icon}</div>
                {val.name}
              </Link>
            ))}
          </SidebarLeft>
          <SidebarRight className="sidebar-right">
            {noNotif ? (
              <div className="dashboard-no-notif">{children}</div>
            ) : (
              <>
                <div className="dashboard-tengah">{children}</div>
                <div className="dashboard-kanan">
                  <DashboardNotif />
                </div>
              </>
            )}
          </SidebarRight>
        </Sidebar>
      </Styles>
    </MustLoggedInWrapper>
  )
}

Dashboard.defaultProps = {
  noNotif: true,
}

export default connect(mapStateToProps)(Dashboard)
