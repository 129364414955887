import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  width: 20%;
  background: #333333;
  color: white;
  padding-bottom: 20px;

  .left-nav {
    /* border-radius: 32px 0px 0px 32px; */
    /* margin-left: 30px; */
    padding: 12px 20px;
  }

  .left-nav.active {
    background: rgba(255, 255, 255, 0.15);
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    .left-nav {
      margin-left: 0;
    }
  }
`

export default function SidebarLeft({ children, ...props }) {
  return <Styles {...props}>{children}</Styles>
}
