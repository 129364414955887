import React from "react"
import styled from "styled-components"

import SidebarLeft from "./sideleft"
import SidebarRight from "./sideright"

const Styles = styled.div`
  display: flex;
  max-width: 1400px;
  margin: auto;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`

function Sidebar({ children, ...props }) {
  return (
    <Styles {...props} className="taman-sidebar">
      {children}
    </Styles>
  )
}

export { Sidebar, SidebarLeft, SidebarRight }
