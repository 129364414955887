import styled from "styled-components"

import greetingWallpaper from "../../../images/dashboard/greeting-wallpaper.png"

export const Styles = styled.div``

export const BerandaTutorStyles = styled.div`
  .beranda-greeting {
    background: url(${greetingWallpaper}) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 220px;
    padding: 40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    h1 {
      padding-bottom: 1rem;
    }

    div {
      max-width: 400px;
    }
  }

  .dompet-tutor {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media (max-width: 767px) {
  }
`
