import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import * as sessionSelectors from "rdx/slices/session/selectors"

import homeDashboard from "images/home-dashboard.png"

const Styles = styled.section`
    .greeting {
      background: url(${homeDashboard}) no-repeat center center;
      background-size: contain;
      min-height: 256px;
      padding: 80px 0 110px 60px;

    h1 {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 66px;
    }

    p {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.0094em;
      color: #919191;
      }
    }

    @media (max-width: 1268px) {
      .greeting {
        background-size: cover;
        padding: 10px;

        h1 {
          font-size: 38px;
        }
      }
    }
`
const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function Top({user}) {
  return (
    <Styles>
      <div className="greeting">
        <h1>Welcome Back, {user.first_name}!</h1>
        <p>Lanjutkan kelasmu dan dapatkan piring cantik!</p>
      </div>
    </Styles>
  )
}

export default withConnect(Top)