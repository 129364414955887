import styled from "styled-components"

export const StylesTutee = styled.div`
  margin-top: 20px;
  .subJudulTutee {
    margin-top: 20px;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: 0.15px;

    /* Gray II */

    color: #52575c;
  }

  @media (max-width: 1024px) {
    .card-container {
      width: 100%;
    }
  }
`
