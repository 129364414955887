import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Dashboard from "../../components/Dashboard"
import DashboardBeranda from "../../components/Dashboard/Beranda"

const DashboardPage = () => (
  <Layout noFooter>
    <SEO title="Dashboard Beranda" />
    <Dashboard chosen="Beranda">
      <DashboardBeranda />
    </Dashboard>
  </Layout>
)

export default DashboardPage
