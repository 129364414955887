/* eslint-disable eqeqeq */
import React from "react"
import { navigate } from "gatsby"

import Action from "./action"
import { StylesTutee } from "./style-tutee"
import DashboardSlimCardNew from "components/Classes/ClassesCard/DashboardSlimCardNew"
import { deleteOrder } from "services/order"
import { getProgress, formatDateTime } from "services/utils"
import ReadMore from "components/ReadMoreWrapper"

export default function BerandaTutee({ orderList, joinedKelas, setOrderList }) {
  const unpaidOrders = orderList.filter(val => {
    return !val.paid
  })
  unpaidOrders?.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })

  const content = unpaidOrders?.length > 0 ? (
      unpaidOrders.map((order, index) => {
        const kelas = order.paket_pembayaran.kelas
        kelas.progress = getProgress(
          new Date(kelas.timeline_start),
          new Date(kelas.timeline_end)
        )
        return (
          <DashboardSlimCardNew
            key={index}
            className="dashboard-card"
            image={kelas.logo}
            code={kelas.slug}
            title={kelas.name}
            type={kelas.type}
            progress={kelas.progress}

            date={formatDateTime(order.date)}
            action="Complete"
            onAction={() =>
              navigate(`/payment?token=${order.transaction_token}`)
            }
            cancel="Cancel"
            onCancel={() => {
              const r = confirm(
                "Are you sure you want to cancel this payment?"
              )
              if (r == true) {
                deleteOrder(order.id).then(() => {
                  setOrderList(
                    orderList
                      .slice(0, index)
                      .concat(orderList.slice(index + 1))
                  )
                })
              }
            }}
          />
          
        )
      })
    ) : (
      <div style={{ color: "#6a6a6a" }}>Nothing to show</div>
    )

  return (
    <>
      <StylesTutee>
        <Action dataatas={joinedKelas} />

        <h5 style={{ marginBottom: "20px" }}>Updates</h5>
        {unpaidOrders?.length > 5 ? 
          <ReadMore> 
            {content}
          </ReadMore>
          :
          content
      }
      </StylesTutee>
    </>
  )
}
