import React, { useState, useEffect } from "react"
import styled from "styled-components"
import arrow from "images/dashboard/arrow.svg"

const Styles = styled.section`
    font-family: 'Avenir';
    font-style: normal;
    margin-top: 20px;
    font-weight: 400;
    margin-bottom: 30px;

    .action-section {
        display: flex;
        overflow-x: scroll;
        align-items: flex-start;
        padding: 0px;
        gap: 12px;
        align-self: stretch;
    }
    
    .update-card {
        background: linear-gradient(109.8deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        box-sizing: border-box;
        display: flex;
        width: 480px;
        height: 261px;
        left: 35px;
        top: 40px;
        color: #FFFFFF;
        margin: 12px auto;

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
        border-radius: 12px;
    }

    .desc {
        padding: 35px;

        .head-title {
            font-size: 12px;
            font-weight: 800;
            line-height: 19px;
            letter-spacing: 0.07em;
            text-align: left;
        }

        .title {
            font-family: Source Serif Pro;
            font-size: 34px;
            font-weight: 700;
            line-height: 43px;
            letter-spacing: 0.0156em;
            text-align: left;
        }

        .subtitle {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0.025em;
            text-align: left;
        }

        .div-link {
            display: flex;
            margin-top: 10%;
            align-items: center;
            justify-content: right;
            
            div {
                padding-left: 5px;
            }
        }
    }

    @media (max-width: 1268px) {
        .desc {
            padding: 5%;
        }
    }
`

export default function Action({ dataatas }) {

  return dataatas?.length > 0 ? (
    <Styles>
        <h5 style={{ marginBottom: "10px" }}>Quick Actions</h5>

        <div className="action-section">
        {dataatas.map((product, index) => {
            const kelas = product.pesanan.paket_pembayaran.kelas
            return (
                <div className="update-card" key={index} style={{ 
                    padding: "0",
                    background:`url(${kelas.logo}) no-repeat center center`,
                    backgroundSize: "cover",
                    display: `flex`,
                    borderRadius: `12px`
                    }}>
                    <div style={{background: `linear-gradient(109.8deg, #000000 0%, rgba(0, 0, 0, 0) 100%)`, alignItems: `strecth`, width:`480px`, borderRadius: `12px`}}>
                    <div className="desc">
                        <h6 className="head-title"></h6>
                        <h6 className="title" style={{height: `45px`, overflow: `hidden`, textOverflow: `ellipsis`, whiteSpace: 'nowrap'}}>{kelas.name}</h6>
                        <p className="subtitle" style={{paddingBottom: `50px`}}>by {kelas.owner.first_name +
                            " " +
                            kelas.owner.last_name}</p>
                        {kelas.meet_url ? (
                            <a href={kelas.meet_url} style={{color:`white`}}>
                        <div className="div-link">
                            <img src={arrow} />
                            <div>GO TO ZOOM</div>
                        </div>
                        </a> 
                        ) : <></>
                    }   
                    </div>
                    </div>
                </div>
            )
        })}
        </div>
    </Styles>
  ) : (
    <div style={{ color: "#6a6a6a" }}>Nothing to show</div>
  )
}
