// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  display: flex;

  .class-image-mini {
    width: 100px;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin: 15px 20px;
    border-radius: 8px;
  }

  .class-info {
    width: 100%;
  }

  .class-schedule {
    min-width: 200px;
  }

  .class-date,
  .class-time {
    display: flex;

    margin-bottom: 0.5em;
    align-items: center;
    color: #a0a4a8;

    font-size: 13px;
  }

  .location,
  .date,
  .time {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .btn-area {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 767px) {
    justify-content: center;

    .class-image-mini {
      border-radius: 8px;
      margin-top: 24px;
    }

    .class-date, class-time {
      justify-content: center;
    }

    .btn-area {
      justify-content: center;
    }
  }
`
