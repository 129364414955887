// Generic imports
import React from "react"
import PropTypes from "prop-types"

// Other components imports
import Image from "components/image"
import Button from "components/Button"
import UpdateCard from "./update-card"
import { Styles } from "./style"

export default function DashboardSlimCard(props) {
  const generateImage = image => {
    return (
      <Styles>
        <div
          className="class-image-mini"
          style={{ backgroundImage: `url(${image})` }}
        />
      </Styles>
    )
  }

  const generateContent = (date, action, onAction, cancel, onCancel) => {
    if (date !== undefined)
      return (
        <Styles>
          <div className="row class-info">
            <div className="col class-schedule">
              <div className="class-date">
                <Image imgName="calendar.png" alt="Date" className="date" />
                {date}
              </div>
            </div>
            <div className="col class-paybutton w-100 px-0">
              <div className="btn-area">
                <Button
                  style={{ borderRadius: "10px", margin: "5px" }}
                  onClick={onAction}
                >
                  {action}
                </Button>
                <Button
                  style={{ borderRadius: "10px", margin: "5px" }}
                  onClick={onCancel}
                  className="secondary"
                >
                  {cancel}
                </Button>
              </div>
            </div>
          </div>
          
        </Styles>
      )
  }

  return (
      <UpdateCard
      code={props.code}
      imageComponent={generateImage(props.image)}
      onClickType={props.onClickType}
      title={props.title}
      type={props.type}
      typeOnTitle={false}
      content={generateContent(
        props.date,
        props.action,
        props.onAction,
        props.cancel,
        props.onCancel
      )}
      />
  )
}

DashboardSlimCard.propTypes = {
  image: PropTypes.any.isRequired,
  code: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClickType: PropTypes.func.isRequired,
  // progress: PropTypes.number.isRequired,
  // author: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  cancel: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
