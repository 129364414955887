import React, { useState } from 'react'
import styled from 'styled-components'

import down from "../../images/Icon.png"

const StyledWrapper = styled.div`
  position: relative;

  .selengkapnya-blur {
    width: 100%;
    height: 100px;
    bottom: 70px;
    position: absolute;
  }

  .baca-selengkapnya {
    padding-top: 30px;
    font-size: 1.1em;
    text-align: center;

    .small-box {
      display: flex;
      margin: 0 auto;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;
      width: 166px;
      height: 31px;
      cursor: pointer;

      background: #FFFFFF;
      border: 1px solid #E7E7E7;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
    }
    
    .small-box:hover {
      opacity: 0.7;
    }
  }
`

export default function ReadMoreWrapper({ children }) {
  const [readMore, setReadMore] = useState(false)
  return (
    <StyledWrapper>
      <div style={readMore ? {} : {
        maxHeight: "480px",
        overflowY: "hidden"
      }}>
        {children}
      </div>
      {readMore ? <></> : <div className="selengkapnya-blur" />}
      <div className="baca-selengkapnya">
        <div className="small-box" onClick={() => setReadMore(!readMore)}>
          <img src={down} width="15px"></img>
          {readMore ? "Lebih ringkas" : "Selengkapnya"}
        </div>
      </div>
    </StyledWrapper>
  )
}
