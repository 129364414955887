// Generic imports
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

// Other components imports
import Button from "components/Button"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"

const mapStateToProps = state => ({
  isLoggedIn: sessionSelectors.isLoggedIn(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function MustLoggedInWrapper(props) {
  const { isLoggedIn, children } = props

  const [redirectTo, setRedirectTo] = useState(null)
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setRedirectTo(encodeURIComponent(window.location.href))
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <div>
      {isLoggedIn ? (
        <div>{children}</div>
      ) : (
        <div className="py-5 text-center text-danger">
          <div className="mb-3 h2">You need to login first!</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              to={`/login/${redirectTo ? `?redirect=${redirectTo}` : ``}`}
              className="secondary login-btn"
              style={{ borderRadius: "10px" }}
            >
              Log In
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default withConnect(MustLoggedInWrapper)
