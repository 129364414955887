import axios from "axios"
import { apiURL } from "services/auth"

export const orderClass = data => axios.post(`${apiURL}/pesanan/`, data)

export const getAllOrder = () => axios.get(`${apiURL}/pesanan/`)

export const getAllTransaction = () => axios.get(`${apiURL}/transaction/`)

export const getAllMyKelas = () => axios.get(`${apiURL}/my-kelas/`)

export const deleteOrder = id => axios.delete(`${apiURL}/pesanan/${id}`)
