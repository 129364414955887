// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  .taman-sidebar {
    max-width: 100%;
    min-width: 80%;
    filter: none;
  }

  .sidebar-left {
    /* padding-top: 1rem; */
    padding-bottom: 5rem;
    background: #f7f7f7;
    color: #cacccf;
    font-weight: bold;

    .left-nav {
      padding: 14px 20px;
      padding-left: 42px;
      margin-top: 2px;
      margin-bottom: 2px;
      display: flex;
      color: black;
    }

    .left-nav:hover {
      text-decoration: none;
    }

    .left-nav.active {
      background: #e7e7e7;
      color: #51b848;
    }
  }

  .sidebar-right {
    display: flex;
    padding: 0;
  }

  .dashboard-no-notif {
    // width: 100%;
    width: 90%;
    margin: 0 auto;
    background: #ffffff;
    padding: 25px 30px;
  }

  .dashboard-tengah {
    width: 70%;
    background: #ffffff;
    padding: 25px 30px;
  }

  .dashboard-kanan {
    width: 30%;
    padding: 25px 30px;
  }

  .profile-container {
    margin: 48px 0;
    text-align: center;

    .prof-pic {
      margin-left: auto;
      margin-right: auto;
      height: 120px;
      width: 120px;
      border-radius: 50%;
    }

    .prof-name {
      font-weight: bold;
      font-size: 20px;
      color: #3b3b3b;
    }

    .prof-role {
      color: #919191;
      font-weight: 400;
    }
  }

  @media (max-width: 450px) {
    .dashboard-no-notif {
      padding: 16px 8px;
    }
  }

  @media (max-width: 1024px) {
    .sidebar-left {
      padding-bottom: 1rem;
    }

    .profile-container {
      margin: 24px 0;
    }
  }
`
