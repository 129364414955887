// Generic imports
import React from "react"
import PropTypes from "prop-types"

// Other components imports
import { Link } from "gatsby"
// import { Styles } from "components/Classes/ClassesCard/style"
import styled from "styled-components"

const Styles = styled.section`
  .card-container {
    background: white;
    border: 1px solid #cacccf;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: stretch;
    overflow-wrap: break-word;
    margin-bottom: 15px;
  }

  .class-content {
    margin: 1rem 1rem;
    margin-bottom: 0.5rem;
    width: calc(100% - 150px);
  }

  .card-header {
    background: white;
    display: flex;
    flex-direction: row;
    border: 0;
    align-items: center;
    padding: 0;
  }

  .card-header a {
    color: black;
    text-decoration: none;
    font-style: normal;
  }

  .card-header h4 {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.0094em;
    text-align: left;
  }

  @media (max-width: 767px) {
    .card-container {
      display: flex;
      flex-wrap: wrap;

      .card-header {
        justify-content: center;
      }

      & > * {
        width: 100%;
      }
    }
  }
`

const ClassCard = props => {
  return (
    <Styles>
      <div className="card-container">
        {props.imageComponent}
        <div className="class-content">
          <div className="card-header">
            <Link to={`/kelas/${props.code}`}>
              <h4>{props.title}</h4>
            </Link>
          </div>
          {props.content}
        </div>
      </div>
    </Styles>
  )
}

ClassCard.propTypes = {
  content: PropTypes.element,
  footer: PropTypes.element,
  header: PropTypes.element.isRequired,
  onClickType: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

ClassCard.defaultProps = {
  authorTitle: "",
  typeOnTitle: true,
}

export default ClassCard
