import React, { useState, useEffect } from "react"

import { Styles } from "./style"
import BerandaTutee from "./beranda-tutee"
import TopDashboard from "./top"

import { getAllOrder, getAllMyKelas } from "services/order"
import { getCreatedClasses } from "services/kelas"
import { getProgress } from "services/utils"

function DashboardBeranda(props) {
  const [activeHead, setActiveHead] = useState("Tutee")
  const [orderList, setOrderList] = useState([])
  const [myClassList, setMyClassList] = useState([])
  const [summary, setSummary] = useState({})
  const [joinedKelas, setJoinedKelas] = useState([])

  const toggleHead = id => {
    setActiveHead(id)
  }

  useEffect(() => {
    getAllMyKelas().then(resp => {
      setJoinedKelas(resp.data)
    })

    getAllOrder().then(resp => {
      setOrderList(resp.data)
    })
    getCreatedClasses().then(resp => {
      let totalRevenue = 0
      let potentialRevenue = 0
      let totalPendaftar = 0
      resp.data.forEach(kelas => {
        kelas.total_pendaftar = 0
        kelas.total_revenue = 0
        kelas.potential_revenue = 0
        kelas.progress = getProgress(
          new Date(kelas.timeline_start),
          new Date(kelas.timeline_end)
        )
        kelas.paket_pembayaran.forEach(paket => {
          paket.pesanan.forEach(pesanan => {
            kelas.potential_revenue += pesanan.total_price
            if (pesanan.paid) {
              kelas.total_revenue += pesanan.total_price
              kelas.total_pendaftar += 1
              if (pesanan.grup?.members?.length > 0) {
                kelas.total_pendaftar += pesanan.grup.members.length
              }
            }
          })
        })
        totalPendaftar += kelas.total_pendaftar
        totalRevenue += kelas.total_revenue
        potentialRevenue += kelas.potential_revenue
      })
      setSummary({
        totalPendaftar,
        totalRevenue,
        potentialRevenue,
      })
      setMyClassList(resp.data)
    })
  }, [])

  // console.log(joinedKelas)
  // console.log(orderList)
  // console.log(myClassList)

  return (
    <Styles>
      <TopDashboard />
      {/* <Updates /> */}
      {/* <Smallnav onToggle={toggleHead} navList={["Tutee", "Tutor"]} /> */}
      
        <div>
        <BerandaTutee
          orderList={orderList}
          joinedKelas={joinedKelas}
          setOrderList={setOrderList}
        /></div>
      
    </Styles>
  )
}

export default DashboardBeranda
