import React from "react"
import styled from "styled-components"

const Styles = styled.div`
  width: 80%;
  background: #ffffff;
  padding: 60px 80px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`

export default function SidebarRight({ children, ...props }) {
  return <Styles {...props}>{children}</Styles>
}
